import PageView from "../views/Home.vue";
import { checkAuthorization } from "../utils/request";

const routes = [
  // 首页
  {
    path: "/",
    redirect: "/home/index",
  },
  {
    path: "/home",
    component: PageView,
    children: [
      {
        path: "index",
        name: "index",
        component: () =>
          import(/* webpackChunkName: "homeindex" */ "../views/home/Index.vue"),
        meta: {
          title: "首页",
        },
      },
    ],
  },
  {
    path: "/account",
    component: PageView,
    children: [
      {
        path: "login",
        name: "Login",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/account/Login.vue"),
        meta: {
          title: "登录",
        },
        beforeEnter: (to, from, next) => {
          const roles = JSON.parse(
            localStorage.getItem(process.env.VUE_APP_ROLES_KEY)
          );
          //console.log(roles)
          if (checkAuthorization() && roles && roles === "user") {
            next({ name: "index" });
          } else {
            next();
          }
          next();
        },
      },
      {
        path: "register",
        name: "Register",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/account/Register.vue"),
        meta: {
          title: "注册",
        },
      },
      {
        path: "mobileLogin",
        name: "mobileLogin",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/account/MobileLogin.vue"),
        meta: {
          title: "登录",
        },
        beforeEnter: (to, from, next) => {
          const roles = JSON.parse(
            localStorage.getItem(process.env.VUE_APP_ROLES_KEY)
          );
          if (checkAuthorization() && roles && roles === "user") {
            next({ name: "mobileIndex" });
          } else {
            next();
          }
          next();
        },
      },
      {
        path: "mobileRegister",
        name: "mobileRegister",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/account/MobileRegister.vue"),
        meta: {
          title: "注册",
        },
      },
    ],
  },
  {
    path: "/home",
    component: PageView,
    children: [
      {
        path: "searchList",
        name: "searchList",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "list" */ "../views/home/Index.vue"),
        meta: {
          title: "检索",
          parentName: "index",
          searchFlag: "picture",
        },
      },
      {
        path: "bookIndex",
        name: "bookIndex",
        component: () =>
          import(/* webpackChunkName: "list" */ "../views/home/Book.vue"),
        meta: {
          title: "民生读物",
          searchFlag: "picture",
        },
      },
      {        
        path: "journalList",
        name: "journalList",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "list" */ "../views/home/Index.vue"),
        meta: {
          title: "数字期刊",
          searchFlag: "video",
        },
      },
      {
        path: "lectureList",
        name: "lectureList",
        component: () =>
          import(/* webpackChunkName: "list" */ "../views/home/Index.vue"),
        meta: {
          title: "惠民讲堂",
          searchFlag: "audio",
        },
      },
      {
        path: "newsIndex",
        name: "newsIndex",
        component: () =>
          import(/* webpackChunkName: "newspaper" */ "../views/home/News.vue"),
        meta: {
          title: "资讯活动",
          searchFlag: "newspaperArticle",
        },
      },
      {
        path: "userCenter",
        name: "userCenter",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/home/UserCenter.vue"),
        meta: {
          title: "个人中心",
          searchFlag: "journalArticle",
        },
      },
    ],
  },
  {
    path: "/list",
    component: PageView,
    children: [
      {
        path: "activityList",
        name: "activityList",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "list" */ "../views/list/Activity.vue"),
        meta: {
          title: "活动预告",
          parentName: "newspaperIndex",
          searchFlag: "picture",
        },
      },
      {
        path: "news",
        name: "news",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/list/News.vue"),
        meta: {
          title: "新闻资讯",
          searchFlag: "newsList",
        },
      },
      {
        path: "newSearchList",
        name: "newSearchList",
        hideInMenu: true,
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/list/SearchList.vue"),
        meta: {
          title: "搜索结果",
          searchFlag: "newSearchList",
        },
      },
    ],
  },
  {
    path: "/mobileItem",
    component: PageView,
    hideInMenu: true,
    detailMenu: true,
    children: [
      {
        path: "MobileBookDetail/:doi",
        name: "MobileBookDetail",
        component: () =>
          import(
            /* webpackChunkName: "item" */ "../views/mobileItem/BookDetail.vue"
          ),
        meta: {
          title: "图书详情",
          parentName: "index",
          searchFlag: "MobileBookDetail",
        },
      },   
      {
        path: "MobileNewsDetail/:doi/:type",
        name: "MobileNewsDetail",
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/mobileItem/NewsDetail.vue"),
        meta: {
          title: "资讯详情",
          parentName: "mobileNews",
          searchFlag: "MobileNewsDetail",
        },
      },
      {
        path: "MobileVideoDetail/:doi",
        name: "MobileVideoDetail",
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/mobileItem/VideoDetail.vue"),
        meta: {
          title: "视频详情",
          parentName: "mobileSearch",
          searchFlag: "MobileVideoDetail",
        },
      },
      {
        path: "MobileAudioDetail/:doi",
        name: "MobileAudioDetail",
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/mobileItem/AudioDetail.vue"),
        meta: {
          title: "视频详情",
          parentName: "mobileSearch",
          searchFlag: "MobileVideoDetail",
        },
      },
      {
        path: "UpdatePassword",
        name: "UpdatePassword",
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/mobileItem/UpdatePassword.vue"),
        meta: {
          title: "修改密码",
          parentName: "mobileSearch",
          searchFlag: "MobileVideoDetail",
        },
      },
      {
        path: "MyCollect",
        name: "MyCollect",
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/mobileItem/MyCollect.vue"),
        meta: {
          title: "我的收藏",
          parentName: "mobileSearch",
          searchFlag: "MobileVideoDetail",
        },
      },
      {
        path: "Footprint",
        name: "Footprint",
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/mobileItem/Footprint.vue"),
        meta: {
          title: "足迹",
          parentName: "mobileSearch",
          searchFlag: "MobileVideoDetail",
        },
      },        
    ]
  },
  {
    path: "/mobileHome",
    component: PageView,
    hideInMenu: true,
    children: [
      {
        path: "mobileIndex",
        name: "mobileIndex",
        component: () =>
          import(/* webpackChunkName: "homeindex" */ "../views/mobileHome/Index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "mobileNews",
        name: "mobileNews",
        component: () =>
          import(
            /* webpackChunkName: "item" */ "../views/mobileHome/mobileNews.vue"
          ),
        meta: {
          title: "咨询活动",
          parentName: "index",
          searchFlag: "mobileNews",
        },
      },      
      {
        path: "mobileSearch",
        name: "mobileSearch",
        component: () =>
          import(
            /* webpackChunkName: "item" */ "../views/mobileHome/MobileSearch.vue"
          ),
        meta: {
          title: "民生读物",
          parentName: "index",
          searchFlag: "mobileSearch",
        },
      },
      {
        path: "mobileUserCenter",
        name: "mobileUserCenter",
        component: () =>
          import(
            /* webpackChunkName: "item" */ "../views/mobileHome/MobileUserCenter.vue"
          ),
        meta: {
          title: "个人中心",
          parentName: "index",
          searchFlag: "mobileSearch",
        },
      },
    ],
  },
  {
    path: "/item",
    component: PageView,
    hideInMenu: true,
    detailMenu: true,
    children: [
      {
        path: "newsDetail/:doi/:type",
        name: "newsDetail",
        component: () =>
          import(/* webpackChunkName: "journal" */ "../views/item/News.vue"),
        meta: {
          title: "资讯详情",
          parentName: "JournalIndex",
          searchFlag: "journalArticle",
        },
      },
      {
        path: "bookDetail/:doi",
        name: "bookDetail",
        component: () =>
          import(
            /* webpackChunkName: "item" */ "../views/item/Book.vue"
          ),
        meta: {
          title: "图书详情页",
          parentName: "index",
          searchFlag: "bookDetail",
        },
      },
      {
        path: "pdfRead",
        name: "pdfRead",
        component: () =>
          import(
            /* webpackChunkName: "item" */ "../views/item/PdfRead.vue"
          ),
        meta: {
          title: "PDF阅读页",
          parentName: "index",
          searchFlag: "picture",
        },
      },
      {
        path: "videoDetail/:doi",
        name: "videoDetail",
        component: () =>
          import(/* webpackChunkName: "item" */ "../views/item/Video.vue"),
        meta: {
          title: "视频详情页",
          parentName: "book",
          searchFlag: "picture",
        },
      },
      {
        path: "audioDetail/:doi",
        name: "AudioDetail",
        component: () => import(/* webpackChunkName: "item" */ "../views/item/AudioDetails.vue"),
        meta: {
            title: '音频详情页',
            // parentName: 'AudioList',
            searchFlag: "audio",
        }
    },      
    ],
  },
];

export default routes;
