export default {
  namespaced: true,
  state: {
    user: undefined,
    roles: undefined,
    expireAt: undefined,
    oldRouter: undefined
  },
  getters: {
    oldRouter: state => state.oldRouter,
    user: (state) => {
      if (!state.user) {
        try {
          const user = localStorage.getItem(process.env.VUE_APP_USER_KEY);
          state.user = JSON.parse(user);
        } catch (e) {
          console.error(e);
        }
      }

      return state.user;
    },
    roles: (state) => {
      if (!state.roles) {
        try {
          const role = localStorage.getItem(process.env.VUE_APP_ROLES_KEY);
          state.roles = JSON.parse(role);
        } catch (e) {
          console.error(e);
        }
      }
    },
    expireAt: (state) => {
      if (!state.expireAt) {
        try {
          const expireAt = localStorage.getItem('expireAt');
          state.expireAt = expireAt;
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user));
    },
    setRoles(state, role) {
      state.roles = role;
      localStorage.setItem(process.env.VUE_APP_ROLES_KEY, JSON.stringify(role));
    },
    setExpireAt(state,expireAt) {
      state.expireAt = expireAt;
      localStorage.setItem('expireAt',expireAt);
    },
    removeUser(state) {
      state.user = undefined;
      localStorage.removeItem(process.env.VUE_APP_USER_KEY);
    },
    removeRoles(state) {
      state.roles = undefined;
      localStorage.removeItem(process.env.VUE_APP_ROLES_KEY);
    },
    removeExpireAt(state) {
      state.expireAt = undefined;
      localStorage.removeItem('expireAt');
    },
    saveNowRouter(state,router) {
      state.oldRouter = router
    }
  },
};
