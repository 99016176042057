// import { resolve } from 'core-js/fn/promise'
// import {logout} from '@/services/user'
import Cookie from "js-cookie";
// import { nextTick } from 'vue/types/umd'
// 401拦截
const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const { message } = options;
    if (response.status === 401) {
      message.error("无此接口权限");
    }
    return response;
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options;
    message.error(error.message);
    return Promise.reject(error);
  },
};

const resp403 = {
  onFulfilled(response, options) {
    const { message } = options;
    if (response.status === 403) {
      message.error(`请求被拒绝`);
    }
    return response;
  },
};

const respprogram200 = {
  onFulfilled(response, options) {
    const { message } = options;
    const { data } = response;
    if (response.status === 200) {
      if (
        data.code === 201 ||
        data.code === 401 ||
        data.code === 402 ||
        data.code === 403 ||
        data.code === 404
      ) {
        message.error(data.msg);
        if (data.code === 401 || data.code == 404) {
          window.location.hash = "/account/login";
        }
      }
      return data;
    }
    return response;
  },
};

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, options) {
    const { message, router } = options;
    const { url, xsrfCookieName } = config;
    const newPath = router.history.current.path;
    //由于目前暂无登录，以下暂时注释
    if (
      newPath.indexOf("userCenter") !== -1 &&
      url.indexOf("Login") === -1 &&
      xsrfCookieName &&
      !Cookie.get(xsrfCookieName)
    ) {
      message.warning("认证 token 已过期，请重新登录");
      window.location.hash = "/account/login";
    }
    return config;
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options;
    message.error(error.message);
    return Promise.reject(error);
  },
};

export default {
  request: [reqCommon], // 请求拦截
  response: [resp401, resp403, respprogram200], // 响应拦截
};
