<template>
  <a-config-provider :locale="zh_CN">
    <div id="app" :class="{ phoneClass: isPhone }">
      <router-view :isPhone="isPhone" />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      zh_CN,
      isRoutesAlive: true, //控制视图是否显示的变量
      isPhone: false,
    };
  },
  created() {
    this.setHtmlTitle();
    this._isMobile();
    this.fnResize();
    // window.onresize = function () {
    //   this.fnResize();
    // };
  },
  watch: {
    $route() {
      this.setHtmlTitle();
      this.fnResize();
    },
  },
  methods: {
    fnResize() {
      var deviceWidth =
        document.documentElement.clientWidth || window.innerWidth;
      if (deviceWidth >= 750) {
        deviceWidth = 750;
      }
      if (deviceWidth <= 320) {
        deviceWidth = 320;
      }
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.isPhone = flag; //移动端    null  pc端           
    },
    reload() {
      this.isRoutesAlive = false; //先关闭
      this.$nextTick(function () {
        this.isRoutesAlive = true; //再打开
      });
    },
    setHtmlTitle() {
      const route = this.$route;
      const meta =
        route.path === "/"
          ? "首页"
          : route.matched[route.matched.length - 1].meta;
      document.title = process.env.VUE_APP_NAME + " | " + meta.title;
      zh_CN;
    },
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a2e36;
}
</style>
