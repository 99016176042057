import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import store from "./store";
import bootstrap from "@/bootstrap";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "./assets/styles/common.less";
import "./assets/styles/antd-vue.less";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css"; //这里注意具体看使用的版本是否需要引入样式，以及具体位置。
Vue.use(VueAwesomeSwiper /* { default global options } */);
import vueAplayer from "vue-aplayer";
//import VueTouch from 'vue-touch'
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.use(vueAplayer);
//Vue.use(VueTouch, {name: 'v-touch'})
Vue.config.productionTip = false;
Vue.use(Antd);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

bootstrap({ router, store, message: Vue.prototype.$message });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
