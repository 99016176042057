<template>
  <div
    id="header"
    :class="{
      loginheaderClass: $route.name === 'Login' || $route.name === 'Register',
    }"
  >
    <div class="container clearfix">
      <div class="logo fl" @click="goToView('/home/index')">
        <img
          src="~@/assets/images/logo-2.png"
          v-if="$route.name === 'Login' || $route.name === 'Register'"
        />
        <img src="~@/assets/images/logo.png" alt="" v-else />
      </div>
      <div class="search fl" v-if="!isLogoin">
        <!-- <input type="text" placeholder="请输入想要搜索的图书" />
			 <a href="#" class="ser-btn"><i></i></a> -->
        <a-input-search
          placeholder="请输入想要搜索的图书"
          enter-button
          @search="onSearch"
          @blur="comSearch"
        />
      </div>
      <div class="user fr" v-if="user && !isLogoin">
        <a><img src="~@/assets/images/user-photo.png" alt="" /></a>
        <span
          ><span @click="goRouter('userCenter')">{{ user }}</span> |<span
            @click="logout()"
            style="margin-left: 5px; font-size: 16px"
            >退出</span
          ></span
        >
        <!-- <span  v-if="!isLogoin" && !user>登录</span> -->
      </div>
      <div class="user fr" v-if="!user && !isLogoin" @click="goRouter('Login')">
        <span class="loginClass"><i></i>登录</span>
      </div>
      <!-- <a-col :span="12" style="text-align: right" v-if="user">
            <router-link class="btn-login ant-btn" :to="{ name: 'index' }">
              <span>{{ user }}</span>
            </router-link>
            <a class="btn-sigin ant-btn" @click="logout" href="javascript:;">
              <span>退出登录</span>
            </a> 
            </a-col> -->
    </div>
    <div class="nav" v-if="menushow && !isLogoin">
      <!-- @click="() => $router.push({ name: item.name })" -->
      <div class="container">
        <a-menu mode="horizontal" :selectedKeys="selectedKeys">
          <a-menu-item
            :key="item.name"
            v-for="item in menuData"
            @click="goView(item.name)"
          >
            {{ item.meta.title }}
          </a-menu-item>
        </a-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { removeAuthorization } from "@/utils/request";
import Cookie from "js-cookie";
export default {
  name: "Header",
  inject: ["reload"],
  data() {
    const menuData = this.getMenuData(this.$router.options.routes);
    const selectedKeys = [this.$route.name];
    return {
      menuData,
      selectedKeys,
      current: ["index"],
      defalutKey: "",
      menushow: true,
      isLogoin: false, //是否为登录页面
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    if (this.$route.name === "Login" || this.$route.name === "Register") {
      this.isLogoin = true;
    } else {
      this.isLogoin = false;
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.removeUser();
        this.removeRoles();
        this.removeExpireAt();
        removeAuthorization();
        return;
      }
      if (!Cookie.get("jdrp")) {
        this.removeUser();
        this.removeRoles();
        this.removeExpireAt();
        removeAuthorization();
        return;
      }
    }
    this.selectedKeys = [];
    if (this.$route.meta && this.$route.meta.parentName) {
      this.selectedKeys.push(this.$route.meta.parentName);
    } else {
      this.selectedKeys.push(this.$route.name);
    }
    //console.log(this.$route.name,'name');
    if (
      this.$route.name == "activityList" ||
      this.$route.name == "newsDetail" ||
      this.$route.name == "videoDetail" ||
      this.$route.name === "newSearchList" ||
      this.$route.name == "pdfRead"
    ) {
      this.menushow = false;
    } else {
      this.menushow = true;
    }
  },
  created() {
    //console.log(this.user)
  },
  watch: {
    $route(to) {
      this.selectedKeys = [];
      if (to.meta && to.meta.parentName) {
        this.selectedKeys.push(to.meta.parentName);
      } else {
        this.selectedKeys.push(this.$route.name);
      }
      if (to.name == "Login" || to.name == "Register") {
        this.isLogoin = true;
      } else {
        this.isLogoin = false;
        if (
          localStorage.getItem("expireAt") &&
          new Date().getTime() >=
            new Date(localStorage.getItem("expireAt")).getTime()
        ) {
          this.removeUser();
          this.removeRoles();
          this.removeExpireAt();
          removeAuthorization();
          return;
        }
        if (!Cookie.get("jdrp")) {
          this.removeUser();
          this.removeRoles();
          this.removeExpireAt();
          removeAuthorization();
          return;
        }
      }
      if (
        to.name == "activityList" ||
        to.name == "newsDetail" ||
        to.name == "videoDetail" ||
        to.name === "newSearchList" ||
        to.name == "pdfRead"
      ) {
        this.menushow = false;
      } else {
        this.menushow = true;
      }
    },
  },
  methods: {
    ...mapMutations("account", ["removeUser", "removeRoles", "removeExpireAt"]),
    goView(name) {
      if (name === "journalList") {
        //window.location.href = "https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=https://wh.cnki.net/read/index?album=V";
        var tempa = document.createElement("a");
        tempa.href ="https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=https://wh.cnki.net/read/index?album=V";
        document.getElementsByTagName("body")[0].appendChild(tempa);
        tempa.click();
      } else if (name === "lectureList") {
        //window.location.href = "https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=https://k.cnki.net/d-jlshmdjt_k";
        var tempa2 = document.createElement("a");
        tempa2.href ="https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=https://k.cnki.net/d-jlshmdjt_k";
        document.getElementsByTagName("body")[0].appendChild(tempa2);
        tempa2.click();
      } else {
        this.$router.push({ name: name });
      }
    },
    logout() {
      this.removeUser();
      this.removeRoles();
      this.removeExpireAt();
      removeAuthorization();
      this.$router.push({ name: "Login" });
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    getMenuData(routes = []) {
      const menuData = [];
      routes.forEach((item) => {
        if (item.name && !item.hideInMenu) {
          const newItem = { ...item };
          delete newItem.children;
          if (item.children) {
            newItem.children = this.getMenuData(item.children);
          }
          menuData.push(newItem);
        } else if (!item.hideInMenu && item.children) {
          menuData.push(...this.getMenuData(item.children));
        }
      });
      return menuData;
    },

    onSearch(value) {
      // let routeMeta = this.$route.meta;
      // this.$router.push({
      //   name: "newSearchList",
      //   params: { key: value },
      // });
      // if (routeMeta) {
      //   let flag = routeMeta.searchFlag;
      //   this.$router.push({
      //     name: "searchList",
      //     params: { flag: flag, key: value },
      //   });
      // }
      this.$emit("onSearch", value);
    },
    comSearch(e) {
      console.log(e);
      // if (this.$route.name === "bookIndex") {
      //   this.$emit('onSearch', e.target.value)
      // }
      // if (this.$route.name === "activityList") {
      //   this.$emit('onSearch', e.target.value)
      // }
      if (this.$route.name === "newSearchList") {
        this.$emit("onSearch", e.target.value);
      }
    },
    goRouter(name) {
      this.$router.push({ name: name });
    },
    onPressSearch(e) {
      if (e.keyCode === 13 && e.key === "Enter") {
        this.$emit("onSearch", e.target.value);
      }

      //this.$emit('onSearch', value)
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/reset.less";
@import "~@/assets/css/common.less";
</style>
<style lang="less" scoped>
#header.loginheaderClass {
  background: #fff;
}
.user {
  line-height: 16px;
  margin-top: 16px;
  cursor: pointer;
  span {
    font-size: 18px;
    color: #fff;
    margin-left: 10px;
    vertical-align: middle;
  }
  a {
    img {
      width: 30px;
      height: 30px;
      border: 1px solid #c4c4c4;
      border-radius: 50%;
    }
  }
  .loginClass {
    margin-top: 10px;
    color: #ea7b19;

    i {
      width: 24px;
      height: 20px;
      margin-top: -3px;
      margin-right: 8px;
      background: url(~@/assets/images/icon-sprites.png) -26px 0;
    }
  }
}
.fr {
  float: right;
}
</style>
<style>
.search
  .ant-input-search.ant-input-search-enter-button.ant-input-group-wrapper {
  display: inline-flex !important;
}
</style>
