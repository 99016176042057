<template>
  <div
    id="footer"
    :class="{
      footerTop: $route.name === 'Login' || $route.name === 'Register',
    }"
  >
    <div class="container">
      <p>
        <span>技术支持：同方知网（北京）技术有限公司吉林省分公司</span> |
        <span>服务咨询：400-819-9993</span>
      </p>
      <p>
        <span>吉ICP备2021009100号-1</span>
        <!-- <span>互联网出版许可证</span
        ><span>新出网证(京)字269号</span> -->
        <img src="../../assets/images/ba.png" style="margin:0 5px 0 5px;padding-bottom: 3px;"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=22010202000955" style="color:#fff">
          <span>吉公网安备 22010202000955号</span>
        </a>
        <span class="xieyi-btn" @click="listenHandleXieyi">隐私政策</span>
      </p>
    </div>
    <a-modal v-model="xieyiVisable" v-if="xieyiVisable" width="50%" dialogClass="xieyi-model" :dialog-style="{ top: '4vh' }" :footer="null">
      <h3 class="xieyi-title">用户注册及使用APP隐私协议</h3>

      <p class="xieyi-content">在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定长春市慧图科技有限公司（以下简称“慧图”）与用户之间关于“吉阅万家”软件服务（以下简称“服务“）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由慧图随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用吉阅万家提供的服务，用户继续使用服务将被视为接受修改后的协议。</p>

      <p class="xieyi-sub-title">一、账号注册</p>

      <p class="xieyi-content">1、用户在使用本服务前需要注册一个“吉阅万家”账号。“吉阅万家”账号应当使用手机号码绑定注册，请用户使用尚未与“吉阅万家”账号绑定的手机号码，以及未被服务根据本协议封禁的手机号码注册“吉阅万家”账号。服务可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。</p>
      <p class="xieyi-content">2、用户注册时应当授权慧图使用其个人信息方可成功注册“吉阅万家”账号。故用户完成注册即表明用户同意服务提取、公开及使用用户的信息。</p>
      <p class="xieyi-content">3、鉴于“吉阅万家”账号的绑定注册方式，您同意服务在注册时将允许您的手机号码及手机设备识别码等信息用于注册。</p>
      <p class="xieyi-content">4、在用户注册及使用本服务时，APP需要搜集能识别用户身份的个人信息以便服务可以在必要时联系用户，或为用户提供更好的使用体验。APP搜集的信息包括但不限于用户的手机号等；慧图同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。</p>

      <p class="xieyi-sub-title">二、用户个人隐私信息保护</p>
      <p class="xieyi-content">1、如果慧图发现或收到他人举报或投诉用户违反本协议约定的，慧图有权不经通知随时对相关内容，包括但不限于用户资料进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁 、设备封禁 、功能封禁的处罚，且通知用户处理结果。</p>
      <p class="xieyi-content">2、因违反用户协议被封禁的用户，可以自行与慧图联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，慧图将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</p>
      <p class="xieyi-content">3、用户理解并同意，慧图有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</p>
      <p class="xieyi-content">4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿慧图与合作公司、关联公司，并使之免受损害。</p>

      <p class="xieyi-sub-title">三、使用规则</p>
      <p class="xieyi-content">1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表慧图的观点、立场或政策，慧图对此不承担任何责任。</p>
      <p class="xieyi-content">2、用户不得利用“吉阅万家”账号或本服务进行如下行为：</p>
        <p class="xieyi-content">(1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</p>
        <p class="xieyi-content">(2) 强制、诱导其他用户关注、点击链接页面或分享信息的；</p>
        <p class="xieyi-content">(3) 虚构事实、隐瞒真相以误导、欺骗他人的；</p>
        <p class="xieyi-content">(4) 利用技术手段批量建立虚假账号的；</p>
        <p class="xieyi-content">(5) 利用“吉阅万家”账号或本服务从事任何违法犯罪活动的；</p>
        <p class="xieyi-content">(6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</p>
        <p class="xieyi-content">(7) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“吉阅万家”正常运营或服务未明示授权的行为。</p>
      <p class="xieyi-content">3、用户须对利用“吉阅万家”账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与慧图无关。如因此给慧图或第三方造成损害的，用户应当依法予以赔偿。</p>

      <p class="xieyi-sub-title">四、其他</p>
      <p class="xieyi-content">1、慧图郑重提醒用户注意本协议中免除慧图责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</p>
      <p class="xieyi-content">2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和慧图之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交慧图住所地有管辖权的人民法院管辖。</p>
      <p class="xieyi-content">3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
      <p class="xieyi-content mar-t-20 mar-b-40">本《协议》版权由慧图所有，慧图保留一切对本《协议》解释的权利。</p>

      <div class="footer-btn">
        <a-button type="primary" @click="listenCloseXieyi">同意</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      xieyiVisable: false,
    }
  },
  methods: {
    listenHandleXieyi() {
      this.xieyiVisable = !this.xieyiVisable
    },
    listenCloseXieyi() {
      this.xieyiVisable = false
    }
  }
};
</script>
<style lang="less" scoped>
#footer.footerTop {
  padding: 40px 0;
  margin-top: 0 !important;
  background: #fff;
  p{
    color: #2c2c2c;
    padding: 0px;
    margin-bottom: 0;
    line-height: 28px;
  }
  
}
span.xieyi-btn{
  display: inline-block;
  margin-left: 30px;
  position: relative;
  cursor: pointer;
  &::before{
    display: inline-block;
    content: "";
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
  }
}

.xieyi-title{
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
}
.xieyi-sub-title{
  font-size: 17px;
  margin-top: 25px;
  margin-bottom: 5px;
  font-weight: bold;
}
.xieyi-content{
  font-size: 16px;
  text-indent: 2em;
  margin-bottom: 0;
  line-height: 28px;
}

.mar-t-20{
  margin-top: 20px;
}
.mar-b-40{
  margin-bottom: 30px;
}

.footer-btn{
  margin-top: 20px;
  text-align: center;
}
</style>
<style lang="less">
.xieyi-model{
  height: 95vh;
  .ant-modal-content{
    height: 100%;
    overflow-y: scroll;
    padding: 20px 10px 10px;
    text-align: justify;
  }
}
</style>
