<template>
	<div
		class="header header-red"
		v-if="!isLogoin && !isuserCenter"
	>
		<div class="header-container">
			<a-row>
				<a-col :span="24">
					<div class="form-group input-group">
						<a-input-search
							placeholder="输入你要搜索的内容"
							style="
                width: 100%;
                height: 0.6rem;
                line-height: 0.6rem;
                border-radius: 0.3rem;
              "
							@search="onSearch"
						/>
					</div>
				</a-col>
			</a-row>
		</div>
	</div>
</template>

<script>
export default {
	name: "Mobile-header",
	data () {
		return {
			isLogoin: false, //是否为登录页面
			isuserCenter: false, //是否为个人中心页面不显示检索框（20210819更新详情也不显示）
		}
	},
	computed: {},
	mounted () {
		if (
			this.$route.name === "mobileLogin" ||
			this.$route.name === "mobileRegister"
		) {
			this.isLogoin = true
		} else {
			this.isLogoin = false
		}
		if (this.$route.name === "mobileUserCenter" || this.$route.name == "UpdatePassword" 
		|| this.$route.name == "MyCollect" || this.$route.name == "Footprint"
		||this.$route.name=="MobileVideoDetail"||this.$route.name=="MobileNewsDetail"
		||this.$route.name=="MobileBookDetail"||this.$route.name=="MobileAudioDetail") {
			this.isuserCenter = true
		} else {
			this.isuserCenter = false
		}
	},
	created () {
		//console.log(this.user)
	},
	watch: {
		$route (to) {
			//console.log(to);
			if (to.name === "mobileLogin" || to.name === "mobileRegister") {
				this.isLogoin = true
			} else {
				this.isLogoin = false
			}
			if (to.name === "mobileUserCenter" || this.$route.name == "UpdatePassword" 
			|| this.$route.name == "MyCollect" || this.$route.name == "Footprint"
			|| this.$route.name == "MobileVideoDetail" || this.$route.name == "MobileNewsDetail"
			|| this.$route.name == "MobileBookDetail" || this.$route.name == "MobileAudioDetail") {
				this.isuserCenter = true
			} else {
				this.isuserCenter = false
			}
		},
	},
	methods: {
		onSearch (value) {
			this.$emit("onSearchMobile", value)
		},
	},
};
</script>
<style lang="less" scoped>
.header {
	width: 100%;
	padding: 0.3rem 0;
	.header-container {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		.form-group {
			margin-bottom: 0px;
		}
		.input-group {
			background: #fff;
			height: 0.6rem;
			line-height: 0.6rem;
			border-radius: 0.3rem;
		}
	}
}
.header-red {
	position: fixed;
	background: #d4000e;
}
</style>
<style scoped>
.input-group >>> input {
	height: 0.6rem;
	line-height: 0.6rem;
	border-radius: 0.3rem;
	font-size: 0.24rem;
	border: none;
	padding-left: 25px;
}
.input-group >>> .ant-input-affix-wrapper {
	font-size: 0.28rem;
}
.input-group >>> .ant-input-suffix {
	font-size: 0.3rem;
}
</style>
