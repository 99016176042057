<template>
  <div
    class="layout"
    :class="{ phoneClass: isPhone, phoneLoginClass: isphoneLogin }"
  >
    <a-layout>
      <a-layout-header v-if="!isPdf">
        <LayoutHeader @onSearch="onSearch" v-if="!this.isPhone && !isPdf" />
        <LayoutMobileHeader
          @onSearchMobile="onSearchMobile"
          v-if="this.isPhone && !isPdf"
        />
      </a-layout-header>
      <a-layout-content
        class="content"
        :class="{
          pdf: $route.name === 'pdfRead',
          mobileSearch: $route.name === 'mobileSearch',
        }"
      >
        <router-view :keywords="searchValue" @bindActive="bindActiveName" />
      </a-layout-content>
      <a-layout-footer v-if="!isPdf">
        <LayoutFooter v-if="!this.isPhone && !isPdf" />
        <LayoutMobileFooter
          v-if="this.isPhone && !isPdf"
          :active="activename"
        />
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<script>
import LayoutHeader from "../components/layout/Header.vue";
import LayoutMobileHeader from "../components/layout/MobileHeader.vue";
import LayoutFooter from "../components/layout/Footer.vue";
import LayoutMobileFooter from "../components/layout/MobileFooter.vue";
export default {
  name: "Home",
  components: {
    LayoutHeader,
    LayoutFooter,
    LayoutMobileHeader,
    LayoutMobileFooter,
  },
  props: ["isPhone"],
  data() {
    return {
      searchValue: "",
      isPdf: false,
      isphoneLogin: false,
      activename: "mobileIndex",
    };
  },
  watch: {
    $route(to) {
      let name = to.name;
      //console.log(to)
      if (name === "pdfRead") {
        this.isPdf = true;
      } else {
        this.isPdf = false;
      }
      if (
        name === "mobileLogin" ||
        name === "mobileRegister" ||
        name === "MyCollect" ||
        name === "UpdatePassword" ||
        name === "Footprint"
      ) {
        this.isphoneLogin = true;
      } else {
        this.isphoneLogin = false;
      }
    },
  },
  mounted() {
    if (this.$route.name === "pdfRead") {
      this.isPdf = true;
    } else {
      this.isPdf = false;
    }
    if (
      this.$route.name === "mobileLogin" ||
      this.$route.name === "mobileRegister" ||
      this.$route.name === "MyCollect" ||
      this.$route.name === "Footprint" ||
      this.$route.name === "UpdatePassword"
    ) {
      this.isphoneLogin = true;
    } else {
      this.isphoneLogin = false;
    }
  },
  created() {},
  methods: {
    bindActiveName(name) {
      this.activename = name;
    },
    onSearch(value) {
      this.searchValue = value;
      if (this.$route.name === "bookIndex") {
        this.$router.push({ name: "bookIndex", params: { key: value } });
        //}else if(this.$route.name === 'activityList'){
        //this.$router.push({ name: 'activityList', params: { key: value } })
      } else {
        this.$router.push({ name: "newSearchList", params: { key: value } });
      }
    },
    onSearchMobile(val) {
      this.searchValue = val;
      if (this.$route.name === "mobileNews") {
        this.$router.push({ name: "mobileNews", params: { key: val } });
        //}else if(this.$route.name === 'activityList'){
        //this.$router.push({ name: 'activityList', params: { key: value } })
      } else {
        this.$router.push({ name: "mobileSearch", params: { key: val } });
      }
    },
  },
};
</script>


<style lang="less">
.layout {
  .pdf.ant-layout-content {
    margin-top: 0;
    width: 100%;
    //css3新单位，view height的简写，是指可视窗口的高度。1vh=视窗高度的1%，height: 100vh;就是屏幕高度的100%，也就是高度满屏
    height: 100vh;
    background-color: rgb(236, 219, 219);
  }
}
</style>
<style scoped>
.phoneClass >>> .ant-layout {
  height: 100% !important;
}
.phoneClass >>> .ant-layout-header {
  height: 100% !important;
}
.phoneClass >>> .ant-layout-footer {
  position: fixed;
  bottom: 0;
  background: #f6f2f0;
  padding: 0.1rem 0;
  width: 100%;
  z-index: 1;
  height: auto;
  box-shadow: 1px 2px 3px 4px rgba(0, 0, 0, 0.25);
}
.phoneLoginClass >>> .ant-layout-footer {
  padding: 0;
}
.phoneClass >>> .ant-layout-content {
  margin-top: 0;
  /* background-color: #fff; */
}
.phoneClass >>> .ant-layout-content.mobileSearch {
  background-color: #fff;
}
</style>