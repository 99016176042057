<template>
  <div
    class="footer-container"
    v-if="!isLogoin && !isuserCenter"
    :bindActive="bindActiveName"
  >
    <a-row>
      <a-col :span="24">
        <ul>
          <li
            v-for="(item, index) in titleList"
            :key="index"
            @click="goRouter(item)"
          >
            <a :class="{ activeClass: item.active }">
              <img :src="item.imgUrl" v-if="!item.active" />
              <img :src="item.activeImgUrl" v-if="item.active" />
              <p>{{ item.name }}</p>
            </a>
          </li>
        </ul>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { removeAuthorization } from "@/utils/request";
import Cookie from "js-cookie";
export default {
  name: "Mobile-footer",
  props: ["active"],
  data() {
    return {
      isLogoin: false, //是否为登录页面
      isuserCenter: false, //是否为个人中心页面
      titleList: [
        {
          name: "首页",
          activeImgUrl: require("../../assets/images/tab-home-pre.png"),
          imgUrl: require("../../assets/images/tab-home.png"),
          active: true,
          routername: "mobileIndex",
          path: "/mobileHome/mobileIndex",
        },
        {
          name: "民生读物",
          activeImgUrl: require("../../assets/images/tab-reading-pre.png"),
          imgUrl: require("../../assets/images/tab-reading.png"),
          active: false,
          routername: "mobileSearch",
          path: "/mobileHome/mobileSearch",
        },
        {
          name: "惠民讲堂",
          activeImgUrl: require("../../assets/images/tab-class-pre.png"),
          imgUrl: require("../../assets/images/tab-class.png"),
          active: false,
          routername: "mobileTeaching",
        },
        {
          name: "资讯活动",
          activeImgUrl: require("../../assets/images/tab-activity-pre.png"),
          imgUrl: require("../../assets/images/tab-activity.png"),
          active: false,
          routername: "mobileNews",
          path: "/mobileHome/mobileNews",
        },
        {
          name: "个人中心",
          activeImgUrl: require("../../assets/images/tab-my-pre.png"),
          imgUrl: require("../../assets/images/tab-my.png"),
          active: false,
          routername: "mobileUserCenter",
          path: "/mobileHome/mobileUserCenter",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    this.bindActiveName(this.$route.name);
    if (
      this.$route.name === "mobileLogin" ||
      this.$route.name === "mobileRegister"
    ) {
      this.isLogoin = true;
    } else {
      this.isLogoin = false;
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.removeUser();
        this.removeRoles();
        this.removeExpireAt();
        removeAuthorization();
        if (
          (this.$route.name === "mobileUserCenter" ||
            this.$route.name === "UpdatePassword" ||
            this.$route.name == "MyCollect" ||
            this.$route.name == "Footprint") &&
          !this.user
        ) {
          this.$router.push({
            name: "mobileLogin",
          });
          return;
        }
        return;
      }
      if (!Cookie.get("jdrp")) {
        this.removeUser();
        this.removeRoles();
        this.removeExpireAt();
        removeAuthorization();
        if (
          (this.$route.name === "mobileUserCenter" ||
            this.$route.name === "UpdatePassword" ||
            this.$route.name == "MyCollect" ||
            this.$route.name == "Footprint") &&
          !this.user
        ) {
          this.$router.push({
            name: "mobileLogin",
          });
        }
        return;
      }
    }
    if (
      this.$route.name == "UpdatePassword" ||
      this.$route.name == "MyCollect" ||
      this.$route.name == "Footprint"
    ) {
      this.isuserCenter = true;
    } else {
      this.isuserCenter = false;
    }
  },
  created() {
    let name = this.$route.name;
    this.bindActiveName(name);
    if (
      name === "UpdatePassword" ||
      name === "MyCollect" ||
      name == "Footprint"
    ) {
      this.isuserCenter = true;
    } else {
      this.isuserCenter = false;
    }
    if (
      name === "MobileBookDetail" ||
      name === "MobileVideoDetail" ||
      name === "MobileAudioDetail"
    ) {
      this.titleList.map(function (data) {
        if ("mobileSearch" === data.routername) {
          data.active = true;
        } else {
          data.active = false;
        }
      });
    } else if (name === "MobileNewsDetail") {
      this.titleList.map(function (data) {
        if ("mobileNews" === data.routername) {
          data.active = true;
        } else {
          data.active = false;
        }
      });
    } else {
      this.titleList.map(function (data) {
        if (name === data.routername) {
          data.active = true;
        } else {
          data.active = false;
        }
      });
    }
  },
  watch: {
    $route(to) {
      this.bindActiveName(to.name)
      if (to.name === "mobileLogin" || to.name === "mobileRegister") {
        this.isLogoin = true;
      } else {
        this.isLogoin = false;
        if (
          localStorage.getItem("expireAt") &&
          new Date().getTime() >=
            new Date(localStorage.getItem("expireAt")).getTime()
        ) {
          this.removeUser();
          this.removeRoles();
          this.removeExpireAt();
          removeAuthorization();
          if (
            (to.name === "mobileUserCenter" ||
              to.name === "UpdatePassword" ||
              to.name == "MyCollect" ||
              to.name == "Footprint") &&
            !this.user
          ) {
            this.$router.push({
              name: "mobileLogin",
            });
          }
          return;
        }
        if (!Cookie.get("jdrp")) {
          this.removeUser();
          this.removeRoles();
          this.removeExpireAt();
          removeAuthorization();
          if (
            (to.name === "mobileUserCenter" ||
              to.name === "UpdatePassword" ||
              to.name == "MyCollect" ||
              to.name == "Footprint") &&
            !this.user
          ) {
            this.$router.push({
              name: "mobileLogin",
            });
          }
          return;
        }
      }
      let name = to.name;
      if (
        name === "MobileBookDetail" ||
        name === "MobileVideoDetail" ||
        name === "MobileAudioDetail"
      ) {
        this.titleList.map(function (data) {
          if ("mobileSearch" === data.routername) {
            data.active = true;
          } else {
            data.active = false;
          }
        });
      } else if (name === "MobileNewsDetail") {
        this.titleList.map(function (data) {
          if ("mobileNews" === data.routername) {
            data.active = true;
          } else {
            data.active = false;
          }
        });
      } else {
        this.titleList.map(function (data) {
          if (name === data.routername) {
            data.active = true;
          } else {
            data.active = false;
          }
        });
      }
      if (
        name == "UpdatePassword" ||
        name == "MyCollect" ||
        name == "Footprint"
      ) {
        this.isuserCenter = true;
      } else {
        this.isuserCenter = false;
      }
    },
    active: function () {
      console.log(this.active);
      let _self = this;
      this.titleList.map(function (data) {
        if (_self.active === data.routername) {
          data.active = true;
        } else {
          data.active = false;
        }
      });
    },
  },
  methods: {
    bindActiveName(name) {
      this.titleList.map(function (data) {
        if (name === data.routername) {
          data.active = true;
        } else {
          data.active = false;
        }
      });
    },
    ...mapMutations("account", ["removeUser", "removeRoles", "removeExpireAt"]),
    goRouter(item) {
      this.titleList.map(function (data) {
        data.active = false;
      });
      item.active = true;
      if (item.routername == "mobileTeaching") {
        // window.location.href =
        //   "https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=https://k.cnki.net/Mobile/Home/Agency?code=jlshmdjt_k";
        var tempa = document.createElement("a");
        // tempa.href ="https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=https://k.cnki.net/Mobile/Home/Agency?code=jlshmdjt_k";
        tempa.href ="https://k.cnki.net/dptmobile/custom/jlshmdjt_k";
        document.getElementsByTagName("body")[0].appendChild(tempa);
        tempa.click();
      }
      if (item.routername === "mobileUserCenter" && !this.user) {
        this.$router.push({
          name: "mobileLogin",
        });
        return;
      }
      this.$router.push({ path: item.path });
    },
  },
};
</script>
<style lang="less" scoped>
.footer-container {
  height: 0.8rem;
  ul {
    overflow: hidden;
    height: 100%;
    margin-bottom: 0;
    li {
      height: 100%;
      float: left;
      width: 20%;
      text-align: center;
      img {
        height: 0.36rem;
        width: 0.36rem;
      }
      a.activeClass {
        color: #c23531;
      }
      p {
        margin-bottom: 0;
        font-size: 0.24rem;
        margin-top: 4px;
        font-weight: bold;
      }
    }
  }
}
</style>
<style scoped>
.footer-container >>> .ant-row {
  height: 100%;
}
.footer-container .ant-col-24 {
  height: 100%;
}
</style>
