import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./indexRoutes";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  scrollBehavior () { //to, from, savedPosition   返回顶部
    return { x: 0, y: 0 }
  }
});

export default router;
